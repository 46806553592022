<template>
    <main class="container">
        <nav>
            <ul>
                <li><strong>Repas de la semaine</strong></li>
            </ul>
            <ul>
                <li><a href="javascript:;" v-bind="{'aria-current': view === 'meals' ? 'page' : null}" @click="view = 'meals'">Repas de la semaine</a></li>
                <li><a href="javascript:;" v-bind="{'aria-current': view === 'menus' ? 'page' : null}" @click="view = 'menus'">Voir la liste des menus</a></li>
            </ul>
        </nav>
        <div v-if="view === 'meals'">
            <h3>Du {{ startDay.toFormat('cccc dd LLLL') }} au {{ endDay.toFormat('cccc dd LLLL') }}</h3>
            <div>
                <ul>
                    <li v-for="(days, index) in menus" :key="index">
                        <strong>Pour le {{ days.date.toFormat('cccc dd LLLL') }}</strong>
                        <ul>
                            <li v-for="(meal, subindex) in days.meals" :key="subindex">
                                {{ translate(meal.moment) }} - {{ meal.title }}
                                <a href="javascript:;" title="Changer ce menu" @click="change(index, subindex)">
                                    <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                                        <path d="m4.98313549 11.0001422c.5127629-.0086487.94188449.3702084 1.00802876.8665022l.00869353.1164911.00363106.22375c.10896147 3.2252893 2.75974653 5.7931116 5.99650826 5.7931116.1869641 0 .3726754-.0085218.5567494-.0254206l-.2638533-.2674697c-.3905243-.3905243-.3905243-1.0236893 0-1.4142136s1.0236893-.3905243 1.4142136 0l2 2c.3905243.3905243.3905243 1.0236893 0 1.4142136l-2 2c-.3905243.3905243-1.0236893.3905243-1.4142136 0s-.3905243-1.0236893 0-1.4142136l.3182573-.315938c-.202546.0153264-.4063709.0230419-.6111534.0230419-4.2312966 0-7.71113558-3.2905597-7.98333285-7.4894768l-.01246302-.253006-.00405901-.2406498c-.00931402-.5522062.43078706-1.0074083.98299327-1.0167223zm6.72397131-8.70724898c.3604839.36048396.3882135.92771502.0831886 1.32000622l-.0831886.09420734-.3179439.31591182c.2024422-.01531093.4061615-.0230186.6108371-.0230186 4.418278 0 8 3.581722 8 8 0 .5522847-.4477153 1-1 1s-1-.4477153-1-1c0-3.3137085-2.6862915-6-6-6-.1870661 0-.3728784.00853145-.5570517.02544919l.2641585.26744403c.3905243.39052429.3905243 1.02368927 0 1.41421356-.360484.36048396-.927715.3882135-1.3200062.08318861l-.0942074-.08318861-1.99999998-2c-.36048396-.36048396-.3882135-.92771502-.08318861-1.32000622l.08318861-.09420734 1.99999998-2c.3905243-.39052429 1.0236893-.39052429 1.4142136 0z" fill-rule="evenodd"/>
                                    </svg>
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
        <div v-else>
            <div class="grid">
                <h3>Liste des menus disponibles</h3>
                <form>
                    <label for="filter-moment">
                        <select v-model="form.moment" id="filter-moment">
                            <option :value="null">Tous</option>
                            <option value="noon">Midi</option>
                            <option value="evening">Soir</option>
                        </select>
                    </label>
                </form>
            </div>
            <ul>
                <li v-for="(menu, index) in filteredMenus" :key="index">
                    <p>
                        {{ translate(menu.moment) }} - {{ menu.title }}<br />
                        Périodes: {{ months(menu.periods) }}
                    </p>
                </li>
            </ul>
        </div>
    </main>
</template>

<script>
import Menus from '@/assets/menus.json'
import { DateTime } from 'luxon'

const french = {
    january: 'Janvier',
    february: 'Février',
    march: 'Mars',
    april: 'Avril',
    may: 'Mai',
    june: 'Juin',
    july: 'Juillet',
    august: 'Aout',
    september: 'Septembre',
    october: 'Octobre',
    november: 'Novembre',
    december: 'Décembre'
}

export default {
    computed: {
        startDay () {
            return DateTime.now().setLocale('fr')
        },
        endDay () {
            return this.startDay.plus({ days: 7 })
        },
        periodMenus () {
            const currentMonth = this.startDay.setLocale('en').toFormat('LLLL').toLowerCase()
            return Menus.filter(x => x.periods.indexOf(currentMonth) > -1)
        },
        filteredMenus () {
            if (!this.form.moment) return this.periodMenus
            return this.periodMenus.filter(x => x.moment === null || x.moment === this.form.moment)
        }
    },
    data () {
        return {
            view: 'meals',
            menus: [],
            form: {
                moment: null
            }
        }
    },
    created () {
        let firstDayMeals = 2
        if (this.startDay.hour > 10) {
            // We consider that if the hour is > 10, we'll generate the meals for the evening first
            firstDayMeals = 1
        }

        let excluded = []

        let currentDay = this.startDay.setLocale('en')
        while (currentDay < this.endDay) {
            this.menus.push({
                date: currentDay.setLocale('fr'),
                meals: []
            })

            this.menus[this.menus.length - 1].meals.push(this.getRandomMenu(currentDay, 'noon', excluded))
            this.menus[this.menus.length - 1].meals.push(this.getRandomMenu(currentDay, 'evening', excluded))

            excluded += this.menus[this.menus.length - 1].meals.map(x => x.title)

            currentDay = currentDay.plus({ day: 1 })
        }

        if (firstDayMeals === 1) {
            this.menus[0].meals.shift()
            this.menus[this.menus.length - 1].meals.pop()
        }
    },
    methods: {
        rand (arr) {
            if (!arr) return null
            return arr[Math.floor((Math.random() * arr.length))]
        },
        change (index, subindex) {
            let excluded = []
            excluded.push(this.menus[index].meals[subindex].title)
            this.menus.forEach(days => {
                excluded += days.meals.map(x => x.title)
            })

            this.menus[index].meals[subindex] = this.getRandomMenu(this.menus[index].date, this.menus[index].meals[subindex].moment, excluded)
        },
        getRandomMenu (day, moment, excluded) {
            const today = day.setLocale('en').toFormat('cccc').toLowerCase()
            let allowedMenus = this.periodMenus.filter(x => excluded.indexOf(x.title) === -1)
            if (!allowedMenus) {
                allowedMenus = this.periodMenus
            }

            const todaysMenus = allowedMenus.filter(x => x.days.indexOf(today) > -1)

            return {
                ...this.rand(todaysMenus.filter(x => x.moment === null || x.moment === moment)),
                moment
            }
        },
        translate (moment) {
            return (moment === 'noon') ? 'Midi' : 'Soir'
        },
        months (periods) {
            return periods.map(x => french[x]).join(', ')
        }
    }
}
</script>

<style scoped>
    .container>nav { margin: 20px 0 40px }
    h1 { line-height: 40px }
    h1>small {
        font-size: 0.6em;
    }
</style>
